<template>
  <div class="unfound d-f j-c">
    <img src="@/assets/images/404.png" alt="404" width="378px" height="196px" />
    <div class="right">
      <h1>404</h1>
      <p>抱歉，你访问的页面不存在</p>
      <a-button type="primary" @click="back"> 返回首页 </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="less">
.unfound {
  padding-top: 194px;
  box-sizing: border-box;
  .right {
    margin-top: 22px;
    margin-left: 76px;
    h1 {
      font-size: 56px;
      font-family: "PingFang SC";
      font-weight: 400;
      line-height: 64px;
      color: #000000;
      opacity: 0.85;
    }
    p {
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: 400;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.45);
      opacity: 1;
      margin-bottom: 30px;
    }
  }
}
</style>
